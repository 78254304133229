
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Sorry the time for viewing has ended</h1>
        <h3>Wishing Rob and Lisa a lifetime of happiness!</h3>
        
        
      </header>
    </div>
  );
}

export default App;
